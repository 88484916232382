import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#deti"> &gt; Radca(DETI)</a>
            <a
              href="/radca/deti/Boj_s_infekciami_nadcha_a_kasel_u_dietata/"
              className="sel"
            >
              {" "}
              &gt; Boj s infekciami – nádcha a kašeľ u dieťaťa
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_494479543.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Boj s infekciami – nádcha a kašeľ u dieťaťa</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Ráno dieťa ide z domu usmiate a spokojné. Návrat už vyzerá
                    menej optimisticky. Blčiace líca, vodnatá nádcha, zvýšená
                    telesná teplota, celkové rozladenie a oslabenie. Rozvíja sa{" "}
                    <a href="/slovnik_pojmov/#infekcia" target="_blank">
                      infekcia
                    </a>
                    &nbsp;a detský organizmus s ňou začína bojovať. Prvý krok,
                    ktorý treba urobiť, je zmierniť ťažkosti.{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Počiatočné príznaky vírusovej infekcie horných dýchacích ciest
                  často považujeme za banalitu. Môžu však viesť k zápalovým
                  stavom (napr. zápalu ucha, hrtana, priedušiek alebo pľúc), čo
                  si už môže vyžadovať antibiotickú liečbu. Nádcha a kašeľ sú
                  ťažkosti, ktoré signalizujú narušenie správneho fungovania
                  dýchacej sústavy. Objavujú sa v intervale 48 až 72 hodín od
                  kontaktu s vírusom.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="Vírusová infekcia a príznaky ako je nádcha a kašeľ, riešenie OLYNTH®."
                    src="/web/kcfinder/uploads/images/shutterstock_94426129.jpg"
                    style={{
                      width: "380px",
                      height: "253px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "left",
                      margin: "5px 10px 10px 0px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Prvý signál začínajúcej infekcie je celkové rozladenie. Dieťa
                  je unavené, spomalené, ospalé, má lesklé oči, neustále je
                  smädné a má zvýšenú telesnú teplotu. Táto reakcia je pozitívny
                  príznak – organizmus dáva najavo, že začal bojovať s vírusmi.
                  Z dôvodu zápalu sa zvyšuje prekrvenie tkanív, vďaka čomu sa
                  obranyschopné bunky rýchlejšie dostanú k zdroju infekcie a
                  vírusy pri zvýšenej telesnej teplote zahynú. Je nevyhnutné
                  zostať doma a odpočívať, aby sme organizmu pomohli prekonať
                  infekciu. V tejto situácii pomôže aj cesnak s teplým mliekom
                  alebo medom, ktorý pôsobí proti baktériám a plesniam.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Dieťa pri infekcii trápi aj nádcha. Spočiatku je vodnatá,
                  postupom času sa zmení na hustú a hlienovitú. Objavuje sa aj
                  opuch nosovej sliznice. Upchatý nos a v ňom nahromadené hlieny
                  spôsobujú problémy s dýchaním nosom, ktoré prekážajú pri
                  bežných denných činnostiach a podieľajú sa aj na prebdených
                  nociach. Najlepším spôsobom boja s nádchou je pravidelné
                  čistenie nosa dieťaťa a odstraňovanie hlienov, ako aj pitie
                  veľkého množstva tekutín (hydratovaním organizmu zvlhčujeme aj
                  sliznicu nosa). Zmierniť príznaky môže pomôcť aj OLYNTH
                  <sup>®</sup> HA 0,05 % alebo OLYNTH<sup>®</sup> 0,05 %. Lieky
                  vo forme nosových roztokových aerodisperzií obsahujú{" "}
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    xylometazolín
                  </a>
                  , vďaka ktorému dochádza k sťahovaniu cievok nosovej sliznice
                  a zmierneniu opuchu a prekrvenia nosovej sliznice, čím sa
                  zároveň redukuje množstvo hlienu. Účinkujú až desať hodín a
                  uvoľnením dýchania nosom môžu prispieť k pokojnému spánku
                  nielen dieťaťa, ale aj jeho rodičov.
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Boj s infekciou u dieťaťa často sprevádza aj kašeľ. Škrabanie
                  v hrdle signalizuje, že v dýchacích cestách vznikol zápalový
                  stav (v dôsledku preniknutia vírusov) a sliznica hrdla je
                  podráždená. Deti môže trápiť suchý aj vlhký kašeľ. Suchý kašeľ
                  sa najčastejšie objavuje na začiatku ochorenia. Vtedy je
                  vhodné siahnuť po liekoch na zmiernenie kašľa. Pri vlhkom
                  kašli (v pokročilej fáze ochorenia) je hlien nahromadený v
                  horných dýchacích cestách vlhký, hustý a len ťažko sa dá
                  vykašlať. V takom prípade je vhodné použiť lieky na podporu
                  vykašliavania.
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Ak dieťa bojuje s infekciou, kašle a telesná teplota prekročí
                  38,5 °C, treba podať lieky proti horúčke. Pomôže aj sprchovanie vlažnou vodou.
                   Používajú sa, aby organizmus
                  vydal teplo do okolia. Zároveň je dôležité podávať veľké
                  množstvo tekutín. Horúčka neprospieva vnútorným orgánom,
                  nervovej sústave ani obehovej sústave. Vysoká telesná teplota
                  je signál, že sa treba ísť poradiť s lekárom.
                </p>
                <p className="p2">&nbsp;</p>
                <p className="p4">
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <span className="s1">Literatúra:&nbsp;</span>
                  </span>
                </p>
                <ol className="ol1">
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      Samoliński, <em>Przewlekłe nieżyty nosa</em>, [v:]
                      „Alergia”, 2013, č. 4, s. 49-55.
                    </span>
                  </li>
                  <li className="li1">
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      Kasperska-Zając,{" "}
                      <em>Kaszel w praktyce klinicznej - część I</em>, [v:]
                      „Alergia”, 2014, č. 3, s. 4-7.
                    </span>
                  </li>
                </ol>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a href="/radca/deti/upchaty_nos_u_detC3AD/" className="box">
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/GettyImages-135538267.jpg)"}} />
                  <span className="title">Upchatý nos u detí</span>
                </a>
                <a
                  href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/shutterstock_1668728821.jpg)"}} />
                  <span className="title">
                    Imunita dieťaťa – dbajte a posilňujte
                  </span>
                </a>
                <a href="/radca/deti/ako_naucit_dieta_siakat/" className="box">
                  <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/artykuly/dzieci_poradnik_03.jpg)"}} />
                  <span className="title">Ako naučiť dieťa siakať?</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
